/** The colors that can be used for the divider */
export type DividerColor = 'darkGrey' | 'lightGrey' | 'white';

/**
 * IDivider Interface for the Divider component
 *
 * @interface IDivider
 */
export interface IDivider {
  /**
   * The color of divider to display.
   *
   * @memberof IDivider
   * @member {DividerColor} type
   */
  type: DividerColor;
  /**
   * The optional classes to be applied to the component.
   *
   * @memberof IDivider
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
}

/**
 * Divider Class Map The class map for the divider component.
 *
 * @constant
 */
const dividerClassMap = {
  darkGrey: 'border-neutral-600 bg-neutral-600',
  lightGrey: 'border-neutral-300 bg-neutral-300',
  white: 'border-neutral-100 bg-neutral-100',
};

/**
 * Divider - Divider Element for separating content.
 *
 * @param {IDivider} props - The props for the Divider component
 * @returns {React.FC<IDivider>} Divider Component
 */
const Divider: React.FC<IDivider> = ({ type, classes = '' }: IDivider) => {
  const dividerClasses = `border-top-[.5px] w-full ${dividerClassMap[type]} ${classes}`;
  return <hr data-testid="divider" className={dividerClasses} />;
};

export default Divider;
